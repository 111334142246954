import { useMutation, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { uuid } from 'uuidv4';


const addRuleBaseGQL = gql`
mutation CreateRuleBase($ruleBaseId: String) {
  addRuleBase(where: {ruleBaseId: $ruleBaseId}) {
    ruleBaseId
  }
}
`

export const AddRuleBase = () => {
  const [newRuleBase, setNewRuleBase] = useState("")
  let navigate = useNavigate()

  const [createRuleBaseDB, { loading, error, data }] = useMutation(addRuleBaseGQL, {
  });

  useEffect(() => {
    if (data && data.addRuleBase) {
      navigate("/rulebase/" + newRuleBase + "/edit")
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  function createRuleBase() {
    const ruleBaseId = uuid();
    setNewRuleBase(ruleBaseId);
    createRuleBaseDB({ variables: { ruleBaseId } })
  }

  return (
    <Button
      variant="outlined"
      type="add"
      className="btn btn-primary"
      onClick={evt => {
        evt.preventDefault()
        createRuleBase()
      }}>
      Add RuleBase
    </Button>

  )
}