import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import { DeleteOutline } from '@mui/icons-material'

const searchRules = gql`
    query searchRules($searchString: String!, $ruleBaseId: String) {
        customer{
            searchRules(filter: {searchString: $searchString, ruleBaseId: $ruleBaseId}){
                items {
                    customerId
                    ruleBaseId
                    ruleId
                    ruleName
                    updatedAt
                }
            }
            getRuleBases{
                items {
                  ruleBaseId
                  ruleBaseName
            }
            }
        }
    }
`;

const deleteRuleGQL = gql`
    mutation DeleteRule($ruleBaseId: String!, $ruleId: Int!, $isDeletedOn: String!) {
    updateRule(where: {ruleBaseId: $ruleBaseId, ruleId: $ruleId, isDeletedOn: $isDeletedOn}) {
        ruleId
        isDeletedOn
    }
  }
`;

export const SearchResults = () => {
    const today = (new Date()).toISOString()
    const { searchQuery: searchString, ruleBaseId, ruleBaseId: ruleBaseIdFromParam } = useParams()
    let navigate = useNavigate()

    const { loading, error, data } = useQuery(searchRules, {
        variables: { searchString, ruleBaseId },
    });

    const [deleteRule] = useMutation(deleteRuleGQL);

    function displayLocalTime(myDate) {
        const date = new Date(myDate)
        return date.toLocaleString()
    }

    const deleteRuleAction = (ruleId, ruleBaseId) => {
        let confirmAction = window.confirm("Are you sure to delete this rule?");
        //console.log("RULE CHECK, ruleId:", ruleId)
        if (confirmAction) {
            deleteRule({ variables: { ruleBaseId, ruleId, isDeletedOn: today } })
        } else {
            alert("Action canceled");
        }
    }

    function matchingRuleBase(ruleBaseId) {
        let ruleBases = data.customer.getRuleBases.items
        for (var ruleBase in ruleBases) {
            if (ruleBaseId === ruleBases[ruleBase].ruleBaseId) {
                return ruleBases[ruleBase].ruleBaseName
            }
        }
        return ""
    }

    if (loading) return <p>Searching...</p>;
    if (error) return <p>Searchbar Error: </p>;
    if (data) {
    }

    return (
        <>
            <TableContainer style={{ marginTop: "30px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rule ID</TableCell>
                            {ruleBaseIdFromParam && ruleBaseIdFromParam !== null ? (
                                ""
                            ) :
                                <TableCell align="left">Rulebase</TableCell>
                            }
                            <TableCell align="left">Rule Name</TableCell>
                            <TableCell align="left">Last Updated</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {data.customer.searchRules.items.map(({ ruleId, ruleName, updatedAt, ruleBaseId, index }) => (
                            <TableRow
                                key={index}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell align="left"
                                    onClick={() => {
                                        navigate("/rule/" + ruleBaseId + "/" + ruleId)
                                    }}
                                >{ruleId}</TableCell>
                                {ruleBaseIdFromParam && ruleBaseIdFromParam !== null ? (
                                    ""
                                ) :
                                    <TableCell align="left"
                                        onClick={() => {
                                            navigate("/rule/" + ruleBaseId + "/" + ruleId)
                                        }}
                                    >{matchingRuleBase(ruleBaseId)}
                                    </TableCell>
                                }
                                <TableCell align="left"
                                    onClick={() => {
                                        navigate("/rule/" + ruleBaseId + "/" + ruleId)
                                    }}
                                >{ruleName}
                                </TableCell>

                                <TableCell align="left"
                                    onClick={() => {
                                        navigate("/rule/" + ruleBaseId + "/" + ruleId)
                                    }}
                                >{displayLocalTime(updatedAt)}
                                </TableCell>
                                <TableCell>
                                    <IconButton style={{ borderRadius: '50%', padding: '8px' }} onClick={evt => {
                                        evt.preventDefault()
                                        deleteRuleAction(ruleId, ruleBaseId)
                                    }}>
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )



}