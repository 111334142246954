import { Auth } from 'aws-amplify';
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";


async function signIn(currentUser, navigate) {
    
    try {
      const user = await Auth.signIn(currentUser.username, currentUser.new_password);
      navigate("/")
    } catch (error) {
      console.log('error signing in', error);
    }
  }

export const ForgotPassword = () => {
    const [currentUser, setCurrentUser] = useState()
    let navigate = useNavigate()

    const submitNewPassword = () => (
        Auth.forgotPasswordSubmit(currentUser.username, currentUser.code, currentUser.new_password)
            .then(data => console.log(data))
            .catch(err => console.log(err))
    )

    const handleControlledInputChange = (user) => {
        const newUser = Object.assign({}, currentUser)
        newUser[user.target.name] = user.target.value
        setCurrentUser(newUser)
        
    }

    return (
        <div>
            <form>
                <a className="Box1">
                    <TextField label="Username" type="Name" name="username" required autoFocus className="form-control"
                        onChange={handleControlledInputChange}
                    />
                </a>
                <a className="Box1">
                    <TextField label="Verification Code" type="Name" name="code" required autoFocus className="form-control"
                        onChange={handleControlledInputChange}
                    />
                </a>
                <a className="Box1">
                    <TextField label="New Password" type="Name" name="new_password" required autoFocus className="form-control"
                        onChange={handleControlledInputChange}
                    />
                </a>
                <Button 
                variant="outlined"
                type="Save"
                    onClick={evt => {
                        evt.preventDefault()
                        submitNewPassword ()
                        signIn(currentUser, navigate)
                        

                    }}

                    className="btn btn-primary">Save</Button>
            </form>

        </div>
    )
}