import "./NavBar.css"
// import { Logout } from "../views/unauthenticated/Logout";
import styled from '@emotion/styled'
import { useNavigate } from "react-router-dom";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  Box,
  Button,
  Grid
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import TerminalIcon from '@mui/icons-material/Terminal';
import LogoutIcon from '@mui/icons-material/Logout';
// import { Icon } from 'semantic-ui-react'
import { Auth } from 'aws-amplify';
//TODO KAtie/Scott - ICON

export const NavBar = () => {
  const urlCheck = window.location.href
  const isProd = urlCheck.includes("snaprules.com")
  let navigate = useNavigate()

  const activeCheck = (urlCheck, path) => {
    if (path === "/") {
      const segments = isProd ? urlCheck.split(".com") : urlCheck.split("localhost:3000")
      const isActive = segments[1] === "/"
      return isActive
    } else
      return urlCheck
        .includes(path);
  }

  const StyledIconLink = styled(Button)`
  margin: 3px;
  margin-right: 7px;
  justify-content: flex-start !important;
  border-radius: 0 !important;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: black;
  padding-bottom: 0;
  &:hover {
    color: white;
    background-color: transparent;
  }
`
  const homeArgs = () => [
    'home',
    '/',
    <HomeIcon name='home'
      style={{ position: 'relative', bottom: 1.5, fontSize: 26 }}
    />,
  ]

  const rulesArgs = () => [
    'rules',
    '/rulebases',
    <TerminalIcon
      style={{ position: 'relative', bottom: 1.5, fontSize: 26 }}
    />,
  ]

  const keyArgs = () => [
    'apikeys',
    '/api-keys',
    <VpnKeyIcon
      style={{ position: 'relative', bottom: 1.5, fontSize: 26 }}
    />,
  ]

  const logoutArgs = () => [
    'logout',
    '/login',
    <LogoutIcon
      style={{ position: 'relative', bottom: 1.5, fontSize: 26 }}
    />,
  ]

  const handleClick = (e, path) => {
    if (path == '/login') {
      try {
        Auth.signOut();
        navigate("/login")
      } catch (error) {
        console.log('error signing out: ', error);
      }
    } else
      navigate(path)

  }

  const LinkIconBtn = ([label, path, icon]) => (
    <Grid item xs={12}>
      <StyledIconLink
        variant="text"
        startIcon={icon}
        onClick={(e) =>
          handleClick(e, path)
        }
        style={{
          background: activeCheck(urlCheck, path) ? 'gray' : 'transparent'
        }}
      >
        {label}
      </StyledIconLink>
    </Grid>
  )

  const renderListItem = (
    label,
    type,
    ArgButton,
  ) => (
    <Grid key={type} item xs={12}>
      <div className="hoverIndex">
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'default',

          }}
        >
          {ArgButton}
        </Box>
      </div>
    </Grid>
  )

  return (
    <nav className="sidenav">
      <div className="Title">SNAPRULES</div>
      {renderListItem(
        homeArgs()[1],
        homeArgs()[0],
        LinkIconBtn(homeArgs()),
      )}
      {renderListItem(
        rulesArgs()[1],
        rulesArgs()[0],
        LinkIconBtn(rulesArgs()),
      )}
      {renderListItem(
        keyArgs()[1],
        keyArgs()[0],
        LinkIconBtn(keyArgs()),
      )}
      {renderListItem(
        logoutArgs()[1],
        logoutArgs()[0],
        LinkIconBtn(logoutArgs()),
      )}
    </nav>
  )
}







