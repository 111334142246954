import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { EditCondition } from "./EditConditions"
import { EditMetaData } from "./EditMetaData";
import { EditAttributes } from "./EditAttributes";
import "./EditRule.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { MenuItem, Alert } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Fade from "@mui/material/Fade";


const getRulesGQL = gql`
  query EditRulesGET($ruleBaseId: String!, $ruleId: Int!) {
    customer {
      getRules(filter: {ruleBaseId: $ruleBaseId, ruleId: $ruleId}) {
        items {
            ruleId
            ruleName
            event
            conditions
            isDeletedOn
            rulePriority
            status
            attributes
        }
       }
        getRuleBase(filter: {ruleBaseId: $ruleBaseId}){
            ruleBaseId
            ruleBaseName
            schema
            metaDataSchema
            attributes
      }
    }
  }
`;

const updateRuleGQL = gql`
    mutation EditRulesEDIT($ruleBaseId: String!, $ruleId: Int!, $ruleName: String, $event: String, $conditions: String, $rulePriority: Int, $status: String, $attributes: String) {
    updateRule(where: {ruleBaseId: $ruleBaseId, ruleId: $ruleId, ruleName: $ruleName, event: $event, conditions: $conditions, rulePriority: $rulePriority, status: $status, attributes: $attributes}) {
        ruleName
        event
        conditions
        isDeletedOn
        rulePriority
        status
        attributes
    }
  }
`;

export const EditRule = () => {
    const { ruleBaseId, ruleId } = useParams()
    let navigate = useNavigate()

    const { loading, error, data } = useQuery(getRulesGQL, {
        variables: { ruleBaseId, ruleId },
    });
    const [currentRule, setCurrentRule] = useState()
    const [alert, setAlert] = useState(false)
    const [updateRule] = useMutation(updateRuleGQL, {});



    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (!currentRule) {
        const ruleFromApi = data.customer.getRules.items[0];
        // console.log("customer DATA", data.customer)
        setCurrentRule(ruleFromApi)
        return <p>Loading...</p>;
    }

    function getOperators() {

        let statusList = [
            { v: "active", o: "Active" },
            { v: "inactive", o: "Inactive" },
        ]
        return statusList;
    }

    const handleControlledInputChange = (rule) => {
        const newRule = Object.assign({}, currentRule)
        newRule[rule.target.name] = rule.target.value
        setCurrentRule(newRule)
    }

    const SaveRule = () => {
        updateRule({ variables: { ruleBaseId, ...currentRule } })
        setAlert((prev) => !prev)
        // console.log("alert", alert)
    }

    return (
        <div key={ruleBaseId}>

            <form className="RuleForm">
                <div className="Form__title">EDIT RULE</div>
                <div className="RuleNumber">
                    Rule Number: {ruleId + " "}
                    <Button
                        variant="outlined"
                        type="Save"
                        onClick={evt => {
                            evt.preventDefault()
                            SaveRule()
                        }}
                        className="btn btn-primary">Save</Button>
                    <span className="BackButton">
                        <Button variant="outlined" onClick={() => {
                            navigate(-1)
                        }}>Back</Button>
                    </span>
                </div>
                <Fade
                    in={alert}
                    timeout={{ enter: 1000, exit: 6000 }}
                    addEndListener={() => {
                        setTimeout(() => {
                            setAlert(false)
                        }, 2000);
                    }}
                >
                    <Alert sx={{ zIndex: "1000", marginBottom: "1rem", position: "fixed", bottom: "0", right: "0" }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                        Save Successful
                    </Alert>
                </Fade>
                <div className="Box5">
                    <span className="Box6">
                        <TextField label="Rule Name" type="Name" name="ruleName" autoFocus className="form-control"
                            defaultValue={currentRule.ruleName}
                            onChange={handleControlledInputChange}
                            size="small"
                        />
                    </span>
                    <TextField label="Status" name="status" autoFocus className="form-control" select
                        value={currentRule.status}
                        onChange={handleControlledInputChange}
                        sx={{ width: '15ch', marginRight: "5px" }}
                        size="small"
                    >
                        {getOperators(currentRule.status).map((varItem, index) =>
                            <MenuItem key={index} value={varItem.v}>{varItem.o}</MenuItem>
                        )}
                    </TextField>
                    {currentRule.rulePriority === 0 ? (
                        <TextField label="Priority Level" type="Name" name="rulePriority" autoFocus className="form-control"
                            defaultValue=""
                            onChange={handleControlledInputChange}
                            sx={{ width: '15ch' }}
                            size="small"
                        />
                    ) :
                        currentRule.rulePriority > 1000 || currentRule.rulePriority < 0 ? (
                            <TextField label="Priority Level" type="Name" name="rulePriority" autoFocus className="form-control"
                                value={0}
                                onChange={handleControlledInputChange}
                                sx={{ width: '15ch' }}
                                size="small"
                            />
                        ) :
                            <TextField label="Priority Level" type="Name" name="rulePriority" autoFocus className="form-control"
                                value={currentRule.rulePriority}
                                onChange={handleControlledInputChange}
                                sx={{ width: '15ch' }}
                                size="small"
                            />}


                </div>

                <div className="form-group">

                    <EditAttributes
                        attributes={data.customer.getRuleBase.attributes}
                        setRule={setCurrentRule}
                        rule={currentRule}
                    />
                </div>

                <fieldset>
                    <label className="WhenTitle">When </label>
                    <div className="form-group">
                        <EditCondition
                            rule={currentRule}
                            setRule={setCurrentRule}
                            schema={data.customer.getRuleBase.schema}
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="Name">Then</label>
                        <EditMetaData
                            metaData={data.customer.getRuleBase.metaDataSchema}
                            setRule={setCurrentRule}
                            rule={currentRule}
                            rulePriority={currentRule.rulePriority}
                        />
                    </div>
                </fieldset>
            </form>
        </div>
    );
};