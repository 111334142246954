import React from "react";
import "./EditConditions.css"
import TextField from '@mui/material/TextField';
import { MenuItem } from "@mui/material";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DeleteOutline } from '@mui/icons-material'



export const EditCondition = (props) => {
    const conditions = props.rule.conditions ? JSON.parse(props.rule.conditions) : []
    const rule = props.rule;
    const schema = JSON.parse(props.schema)
    const schemaList = buildSchemaList(schema.properties)

    // console.log("schema", schema.properties)
    function buildSchemaList(properties, prefix = "") {
        let schemaList = {}
        // console.log("properties", properties)
        for (var key in properties) {
            let itemKey = calculatePrefix(prefix, key)
            if (properties[key].type && ["string"].includes(properties[key].type.toLowerCase()) && properties[key].format === "date") {
                let item = {}
                item[itemKey] = { type: properties[key].type, format: properties[key].format }
                schemaList = { ...item, ...schemaList }
            } else if (properties[key].type && ["string", "float", "integer", "number", "boolean"].includes(properties[key].type.toLowerCase())) {
                let item = {}
                item[itemKey] = { type: properties[key].type }
                schemaList = { ...item, ...schemaList }
            } else if (properties[key].type && properties[key].type.toLowerCase() === "object") {
                let subSchemaList = buildSchemaList(properties[key].properties, calculatePrefix(prefix, key))
                schemaList = { ...subSchemaList, ...schemaList }
            } else if (properties[key].type && properties[key].type.toLowerCase() === "array") {
                let subSchemaList = buildSchemaList(properties[key].items.properties, calculatePrefix(prefix, "[array]" + key))
                schemaList = { ...subSchemaList, ...schemaList }
            }
        } return schemaList
    }

    function calculatePrefix(prefix, key) {
        if (prefix === "") {
            return key
        }
        return prefix + "." + key
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#D22B2B",
            },
        },
    });

    function getAllVariables() {
        let varList = []

        for (var item_from_schema in schemaList) {
            if (schemaList[item_from_schema].format) {
                let item = { label: item_from_schema, format: schemaList[item_from_schema].format }
                varList.push(item)
            } else {
                let item = { label: item_from_schema }
                varList.push(item)
            }
        }
        // console.log("varList", varList)
        return varList;
    }

    function getAllVariablesClean() {
        let varList = []

        for (var item_from_schema in schemaList) {
            let clean_item_from_schema = item_from_schema.replaceAll('[array]', '')
            if (schemaList[item_from_schema].format) {
                let item = { label: clean_item_from_schema, format: schemaList[item_from_schema].format }
                varList.push(item)
            } else {
                let item = { label: clean_item_from_schema }
                varList.push(item)
            }
        }
        // console.log("varList", varList)
        return varList;
    }

    function getVariableFromCleanVariable(clean_item) {
        let varList = getAllVariables();
        for (var i = 0; i < varList.length; i++) {
            if (clean_item === varList[i].label.replaceAll('[array]', '')) {
                return varList[i].label;
            }
        }
        return "";
    }

    function getOperators(varValue) {

        let opListEqNeq = [
            { v: "eq", o: "Equal" },
            { v: "neq", o: "Not Equal" }
        ]
        let opListNumber = [
            { v: "gt", o: "Greater Than" },
            { v: "gte", o: "Greater Than or Equal" },
            { v: "lt", o: "Less Than" },
            { v: "lte", o: "Less Than or Equal" },
        ]
        let opListDate = [
            { v: "d_eq", o: "Equal" },
            { v: "d_neq", o: "Not Equal" },
            { v: "d_gt", o: "Greater Than" },
            { v: "d_gte", o: "Greater Than or Equal" },
            { v: "d_lt", o: "Less Than" },
            { v: "d_lte", o: "Less Than or Equal" },
        ]
        let opListString = [
            { v: "in", o: "Is In" },
            { v: "nin", o: "Is Not In" },
            { v: "eq", o: "Equal" },
            { v: "neq", o: "Not Equal" }
        ]
        if (schemaList[varValue]) {
            const itemType = schemaList[varValue].type


            if (["integer", "float", "number"].includes(itemType)) {
                return opListEqNeq.concat(opListNumber)
            } else if (isDate(varValue)) {
                return opListDate
            } else if (["string"].includes(itemType)) {
                return opListString
            } else if (["boolean"].includes(itemType)) {
                return opListEqNeq
            } else {
                return opListEqNeq
            }
        } return []
    }

    function getVariableList(lhsValue) {
        if (schemaList[lhsValue]) {
            let rhs = [""]
            const lhsType = schemaList[lhsValue].type
            // console.log(schemaList, "schemalist")
            for (var currentItemValue in schemaList) {
                let currentItemType = schemaList[currentItemValue].type
                if (lhsType === currentItemType && lhsValue !== currentItemValue) {
                    rhs.push(currentItemValue)
                }
            } return rhs
        } else return []
    }

    function getDateOptions() {
        let dateList = [
            { v: "t", o: "Today" },
            { v: "f", o: "In The Future" },
            { v: "p", o: "In The Past" }
        ]
        return dateList
    }

    function getBooleanOptions() {
        let booleanList = [
            { v: "_T", o: "True" },
            { v: "_F", o: "False" }

        ]
        return booleanList
    }

    function isDate(varName) {
        if (schemaList[varName]) {
            if (schemaList[varName].type === "string" && schemaList[varName].format && schemaList[varName].format === "date") {
                return true
            }
        }
        return false
    }

    function isNumber(varName) {
        if (schemaList[varName]) {
            if (schemaList[varName].type === "integer") {
                return true
            }
        }
        return false
    }

    function isBoolean(varName) {
        if (schemaList[varName]) {
            if (schemaList[varName].type === "boolean") {
                return true
            }
        }
        return false
    }

    function addCondition() {
        let cond = conditions
        cond.push({
            var: "",
            op: "",
            val: [""]
        })
        const updateRuleJson = { ...rule, conditions: JSON.stringify(cond) }
        props.setRule({ ...updateRuleJson })
    }

    const deleteCondition = (num) => {
        let new_conditions = conditions;
        new_conditions.splice(num, 1)
        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const updateConditionValue = (num, val, index) => {
        let value_to_update = val;
        // console.log("updateConditionValue, val", val)
        if (conditions[num].date) {
            value_to_update = Math.abs(value_to_update)
            if (conditions[num].date === "p") {
                value_to_update = value_to_update * -1
            }
        }
        let valArray = conditions[num].val
        if (valArray.length > index) {
            valArray[index] = value_to_update
        } else {
            valArray.push(value_to_update)
        }
        // console.log("valArray", valArray)
        const singleCond = { ...conditions[num], ...{ val: valArray } }
        let new_conditions = conditions;
        new_conditions[num] = singleCond
        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const deleteConditionValue = (num, index) => {
        let valArray = conditions[num].val
        if (valArray.length > index && index !== 0) {
            valArray.splice(index, 1);
        } else {
            return
        }
        const singleCond = { ...conditions[num], ...{ val: valArray } }
        //Override condition of "num" with a whole new condition
        let new_conditions = conditions;
        new_conditions[num] = singleCond

        //set conditions array to state
        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const updateCondition = (num, e) => {
        const singleCond = { ...conditions[num], ...{ [e.target.name]: e.target.value } }
        //Override condition of "num" with a whole new condition
        let new_conditions = conditions;
        new_conditions[num] = singleCond

        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const updateConditionVariable = (num, e) => {
        //rebuild condition of "num"
        const variable_name = getVariableFromCleanVariable(e.target.textContent)
        //find real variable name here
        let singleCond = { ...conditions[num], ...{ ["var"]: variable_name } }

        if (isDate(variable_name)) {
            const cond_date_defaults = { "date": "t", "val": ["0"] }
            singleCond = { ...singleCond, ...cond_date_defaults }
        }

        //Override condition of "num" with a whole new condition
        let new_conditions = conditions;
        new_conditions[num] = singleCond

        //set conditions array to state
        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const updateConditionDate = (num, date_type) => {
        let value_to_update = Math.abs(conditions[num].val[0])
        if (date_type === "p") {
            value_to_update = value_to_update * -1
        } else if (date_type === "t") {
            value_to_update = 0
        }
        const new_val_array = [value_to_update];
        const singleCond = { ...conditions[num], ...{ ["date"]: date_type, ["val"]: new_val_array } }

        //Override condition of "num" with a whole new condition
        let new_conditions = conditions;
        new_conditions[num] = singleCond

        //set conditions array to state
        const updateRuleJson = { ...rule, conditions: JSON.stringify(new_conditions) }
        props.setRule({ ...updateRuleJson })
    }

    const rhsVariable = (val) => {
        if (val?.startsWith("f_")) {
            return val.slice(2)
            //else defer to rhsValue    
        } else return ""
    }

    const rhsValue = (val) => {
        console.log("rhsValue", val)
        if (val?.startsWith("f_")) {
            //defer to rhsVariable
            return ""
        }
        return val
    }

    const validateRhsVariable = (val) => {
        if (val === undefined) {
            return ""

        } else return "f_" + val
    }

    return (
        <div>
            <div className="ConditionForm">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {conditions && conditions.length > 0 && conditions.map((condition_from_map, condition_index) =>
                                <TableRow
                                    key={condition_index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}>

                                    <TableCell align="left">
                                        <Autocomplete
                                            sx={{ marginBottom: "0px", width: "500px" }}
                                            options={getAllVariablesClean()}
                                            className="selectVariable"
                                            disablePortal
                                            size="small"
                                            value={condition_from_map.var.replaceAll('[array]', '')}
                                            onChange={(e) => updateConditionVariable(condition_index, e)}
                                            renderInput={(input) => <TextField {...input} label="Variable" />}

                                        />

                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            select
                                            className="selectOperator"
                                            value={condition_from_map.op}
                                            label="Operator"
                                            name="op"
                                            size="small"
                                            onChange={(e) => updateCondition(condition_index, e)}
                                        >
                                            <MenuItem value=""></MenuItem>

                                            {getOperators(condition_from_map.var).map((single_condition_operator, index) =>
                                                <MenuItem key={index} value={single_condition_operator.v}>{single_condition_operator.o}</MenuItem>
                                            )}
                                        </TextField>
                                    </TableCell>
                                    <TableCell align="left">
                                        {condition_from_map.val.map((single_cond_array_of_val, single_cond_array_of_val_index) =>
                                            <span key={single_cond_array_of_val_index}>
                                                { //Variable is a date
                                                    isDate(condition_from_map.var) ?

                                                        <span sx={{ alignContent: "bottom" }}>
                                                            { //Variable is a date and the selection is "in the future" or "in the past"
                                                                ["f", "p"].includes(condition_from_map.date) ?
                                                                    <TextField
                                                                        sx={{ width: "25%", paddingRight: "5px" }}
                                                                        size="small"
                                                                        label="Days"
                                                                        value={Math.abs(single_cond_array_of_val)}
                                                                        onChange={(e) => updateConditionValue(condition_index, e.target.value, single_cond_array_of_val_index)}>
                                                                    </TextField>
                                                                    : null
                                                            }

                                                            < TextField
                                                                select
                                                                sx={{ paddingLeft: "5px" }}
                                                                className="selectDate"
                                                                value={condition_from_map.date}  //needs to be "t", "f", or "p"
                                                                label="Date"
                                                                name={"val" + single_cond_array_of_val_index}
                                                                size="small"
                                                                onChange={(e) => updateConditionDate(condition_index, e.target.value, single_cond_array_of_val_index)}
                                                            >
                                                                <MenuItem value=""></MenuItem>
                                                                {getDateOptions().map((single_condition_date, index) =>
                                                                    <MenuItem key={index} value={single_condition_date.v}>{single_condition_date.o}</MenuItem>
                                                                )}
                                                            </TextField> </span> :

                                                        isBoolean(condition_from_map.var) ? (
                                                            < TextField
                                                                select
                                                                sx={{ width: "100px", paddingLeft: "5px" }}
                                                                className="selectBoolean"
                                                                value={single_cond_array_of_val}
                                                                label="Value"
                                                                name={"val" + single_cond_array_of_val_index}
                                                                size="small"
                                                                onChange={(e) => updateConditionValue(condition_index, e.target.value, single_cond_array_of_val_index)}
                                                            >
                                                                <MenuItem
                                                                    sx={{ width: "100px", paddingLeft: "5px" }}
                                                                    value="">
                                                                </MenuItem>
                                                                {getBooleanOptions().map((single_condition_boolean, index) =>
                                                                    <MenuItem key={index} value={single_condition_boolean.v}>{single_condition_boolean.o}</MenuItem>
                                                                )}
                                                            </TextField>) :

                                                            // isNumber(condition_from_map.var) ? (
                                                            <span>
                                                                <Autocomplete
                                                                    sx={{ marginBottom: "7px", width: "200px" }}
                                                                    options={getVariableList(condition_from_map.var)}
                                                                    className="selectVariable"
                                                                    disablePortal
                                                                    size="small"
                                                                    value={rhsVariable(single_cond_array_of_val)}
                                                                    onChange={(e) => updateConditionValue(condition_index, validateRhsVariable(e.target.outerText), single_cond_array_of_val_index)}
                                                                    renderInput={(input) => <TextField {...input} label="Variable" />}

                                                                />
                                                                <TextField
                                                                    size="small"
                                                                    type="text"
                                                                    name={"val" + single_cond_array_of_val_index}
                                                                    value={rhsValue(single_cond_array_of_val)}
                                                                    label="Value"
                                                                    onChange={(e) => updateConditionValue(condition_index, e.target.value, single_cond_array_of_val_index)}
                                                                />
                                                            </span>
                                                    // ) :

                                                    // Normal Value box

                                                    // <TextField
                                                    //     size="small"
                                                    //     type="text"
                                                    //     name={"val" + single_cond_array_of_val_index}
                                                    //     value={single_cond_array_of_val}
                                                    //     label="Value"
                                                    //     onChange={(e) => updateConditionValue(condition_index, e.target.value, single_cond_array_of_val_index)}
                                                    // />
                                                }
                                                {single_cond_array_of_val_index > 0 ? (
                                                    <Button
                                                        variant="outlined"
                                                        type="Delete"
                                                        onClick={evt => {
                                                            evt.preventDefault()
                                                            deleteConditionValue(condition_index, single_cond_array_of_val_index)
                                                        }}
                                                        className="btn btn-primary">Delete Value</Button>
                                                ) :
                                                    ["in", "nin"].includes(condition_from_map.op) ? (
                                                        <Button
                                                            variant="outlined"
                                                            type="Add"
                                                            onClick={evt => {
                                                                evt.preventDefault()
                                                                updateConditionValue(condition_index, "new", 10000)
                                                            }}

                                                            className="btn btn-primary">Add Value</Button>
                                                    ) : ''
                                                }

                                            </span>
                                        )}

                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        width="5px">
                                        <ThemeProvider theme={theme}>
                                            <DeleteOutline
                                                type="Delete"
                                                onClick={evt => {
                                                    evt.preventDefault()
                                                    deleteCondition(condition_index)
                                                }}
                                            />
                                        </ThemeProvider>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="AndButton">
                    <Button
                        variant="outlined"
                        type="Add"
                        onClick={evt => {
                            evt.preventDefault()
                            addCondition()
                        }}
                        className="btn btn-primary">+ And</Button>
                </div>
            </div>
        </div >
    )
}