import React from 'react';
import { render } from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import App from './App';
import startAmplify from './components/Auth/startAmplify'
import { Auth } from 'aws-amplify';


const url = process.env.REACT_APP_GRAPHQL_URI;
const region = 'us-east-1';
// const keyAuth = {
//   type: "API_KEY",
//   apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
// };
const jwtAuth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: () =>
    Auth.currentSession().then((s) => s.getIdToken().getJwtToken()),
}
// console.log("jwtAuth", jwtAuth)
const link = ApolloLink.from([
  createAuthLink({ url, region, auth: jwtAuth }),
  createHttpLink({ uri: url })
]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
startAmplify()

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);