import { useQuery, useMutation, gql } from "@apollo/client";
import Button from '@mui/material/Button';
import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const deployRulesGQL = gql`
    mutation deployCust1($customerId: String!, $stage: String!) {
        deployRules(where: {customerId: $customerId, stage: $stage}){
            msg
        }
    }
`

const getCustomer = gql`
  query GetCustomer($customerId: String!) {
    customer(id: $customerId) {
      getCustomer {
        customerId
        customerName
        customerDeployment
    }
    }
  }
`;

const updateCust = gql`
    mutation UpdateCustomer($customerId: String!, $customerDeployment: String) {
        updateCustomer(where: {customerId: $customerId, customerDeployment: $customerDeployment}){
            customerDeployment
        }
    }
`

export const DeployRules = () => {
    const customerId = "1"
    const today = (new Date()).toISOString()
    const [stages, setStages] = useState([])
    //console.log("today", today)

    const { loading: customerLoading, error: customerError, data: customerData } = useQuery(getCustomer, { variables: { customerId } });
    const [deployRules, { loading: deployloading, error: deployerror, data: deploydata }] = useMutation(deployRulesGQL);
    const [updateCustomer, { loading: updateloading, error: updateerror, data: updatedata }] = useMutation(updateCust);

    if (customerLoading) return <p>Loading Customer Data...</p>;
    if (customerError) return <p>Error Loading Customer</p>;
    if (deployloading) return <p>Deploying Rules...</p>;
    if (deployerror) return <p>Error Deploying Rules</p>;

    if (customerData && customerData.customer) {
        const cust = customerData.customer.getCustomer
        //console.log("deployrule CustomerData", customerData)
        if (cust.customerDeployment && cust.customerDeployment.length > 0 && stages.length == 0) {
            let tempStages = JSON.parse(cust.customerDeployment)
            //console.log("deployrule customerDeployment", tempStages)
            //if you dont have lastDeployedAt and url then add blank values
            // if(!(lastDeployedAt in tempStages))
            for (var stageIndex in tempStages) {
                const stageItem = tempStages[stageIndex]
                //console.log("DeployRules stageItem", stageItem)
                if (!stageItem.lastDeployedAt) {
                    tempStages[stageIndex] = { ...tempStages[stageIndex], ...{ lastDeployedAt: '' } }
                }
                if (!stageItem.url) {
                    tempStages[stageIndex] = { ...tempStages[stageIndex], ...{ url: '' } }
                }
                //console.log("stageItem modified", tempStages[stageIndex])
            }
            //loop tempStages
            setStages(tempStages)

            //console.log("stages", tempStages)
        }

    } else {
        return <p>Error Loading Customer</p>;
    }

    function deployRuleAction(stage) {
        deployRules({ variables: { customerId, stage: stage } })
        for (var stageIndex in stages) {
            //console.log("stageItem", stages[stageIndex])
            if (stage == stages[stageIndex].value) {
                //console.log("add deploy date here", stages[stageIndex])
                stages[stageIndex] = { ...stages[stageIndex], ...{ lastDeployedAt: today } }
            }
        }
        //update customer
        //console.log("STAGE CHECK", stages)
        updateCustomer({ variables: { customerId, customerDeployment: JSON.stringify(stages) } })
    }

    function displayLocalTime(myDate) {
        const date = new Date(myDate)
        return date.toLocaleString()
    }

    return (
        <div>
            <div className="DeploymentForm">

                <TableContainer component={Paper}>
                    <Table
                        // sx={{ minWidth: 650 }} 
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Deployment Stage</TableCell>
                                <TableCell />
                                <TableCell>Last Deployment</TableCell>
                                {/* <TableCell align="right">URL</TableCell> */}
                                <TableCell></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stages.length > 0 && stages.map((stage) => (
                                <TableRow
                                    key={stage.value}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {stage.displayName}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell>{displayLocalTime(stage.lastDeployedAt)}</TableCell>
                                    {/* <TableCell align="right">{stage.url}</TableCell> */}
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            type="Add"
                                            onClick={evt => {
                                                evt.preventDefault()
                                                // setSelectedStage(stage.value)
                                                deployRuleAction(stage.value)
                                            }}
                                            className="btn btn-primary">Deploy</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </div>
    )
}