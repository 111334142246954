import React, { useState } from "react";
import "./login.css";
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Box, TextField } from '@mui/material';


export const Login = () => {
  let navigate = useNavigate()

  async function signIn(currentUser) {

    try {
      const user = await Auth.signIn(currentUser.username, currentUser.password);
      navigate("/")
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const [currentUser, setCurrentUser] = useState()

  const sendCode = () => (
    Auth.forgotPassword(currentUser.username)
      .then(data => console.log(data))
      .catch(err => console.log(err))
  )
  const handleControlledInputChange = (user) => {
    const newUser = Object.assign({}, currentUser)
    newUser[user.target.name] = user.target.value
    setCurrentUser(newUser)
  }

  const renderForm = (

    <form className="form">
      <div className="Box1">
        <TextField label="Username" type="Name" name="username" required autoFocus className="form-control"
          onChange={handleControlledInputChange}
        />
      </div>
      <div className="Box1">
        <TextField label="Password" type="password" name="password" required autoFocus className="form-control"
          onChange={handleControlledInputChange}
        />
      </div>

      <Box className="button-container">
        <Button
          variant="contained"
          type="Submit"
          onClick={evt => {
            evt.preventDefault()
            signIn(currentUser)


          }}

          className="btn btn-primary">Sign In</Button>
        <Button variant="text"
          onClick={evt => {
            evt.preventDefault()
            sendCode()
            navigate("/forgotPassword")
          }}
        >Forgot Password</Button>

      </Box>

    </form>

  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title">Welcome to SnapRules!</div>
        {renderForm}
      </div>
    </div>
  )
}