import Amplify from 'aws-amplify'

export const amplifyConfig = {
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    //   authenticationFlowType: 'CUSTOM_AUTH',
    },
    // oauth: {
    //   domain: process.env.REACT_APP_SAML_DOMAIN,
    //   scope: [
    //     'email',
    //     'openid',
    //     'aws.cognito.signin.user.admin',
    //     'profile',
    //     'phone',
    //   ],
    //   redirectSignIn: process.env.REACT_APP_SAML_LOGIN_REDIRECT,
    //   redirectSignOut: process.env.REACT_APP_SAML_LOGOUT_REDIRECT,
    //   responseType: 'code',
    //   client_id: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    //   grant_type: 'authorization_code',
    // },
  }
  const startAmplify = () => {
    // if (!process.env.REACT_APP_COGNITO_REGION) {
    //   throw new Error('Missing Auth env variables')
    // }
    // if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    //   throw new Error('Missing Auth env variables')
    // }
    // if (!process.env.REACT_APP_COGNITO_APP_CLIENT_ID) {
    //   throw new Error('Missing Auth env variables')
    // }
    Amplify.configure(amplifyConfig)
  }
  export default startAmplify