import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import "./EditRuleBase.css"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Fade from "@mui/material/Fade";

const updateRuleBaseGQL = gql`
  mutation updateRuleBase ( $ruleBaseId: String!, $schema: AWSJSON, $ruleBaseName: String, $metaDataSchema: AWSJSON, $attributes: AWSJSON) {
    updateRuleBase(where: { ruleBaseId: $ruleBaseId, schema: $schema, ruleBaseName: $ruleBaseName, metaDataSchema: $metaDataSchema, attributes: $attributes}) {
      ruleBaseId
      ruleBaseName
      schema
      metaDataSchema
      attributes
  }
}
`;

const getRuleBase = gql`
  query getRuleBase($customerId: String!, $ruleBaseId: String!) {
    customer(id: $customerId) {
      getRuleBase(filter: {ruleBaseId: $ruleBaseId}){
          ruleBaseId
          ruleBaseName
          schema
          metaDataSchema
          attributes
    }
  }
}
`;

const deleteRuleBaseGQL = gql`
    mutation DeleteRuleBase($customerId: String!, $ruleBaseId: String!, $isDeletedOn: String) {
    updateRuleBase(where: {customerId: $customerId, ruleBaseId: $ruleBaseId, isDeletedOn: $isDeletedOn }) {
        ruleBaseId
        isDeletedOn
    }
  }
`;

export const EditRuleBase = () => {
    const { ruleBaseId: ruleBaseIdFromParam } = useParams()
    let navigate = useNavigate()
    const today = (new Date()).toISOString()
    const customerId = "3"
    const ruleBaseId = ruleBaseIdFromParam

    const { loading, error, data } = useQuery(getRuleBase, {
        variables: { customerId, ruleBaseId },
    });

    const [currentRuleBase, setCurrentRuleBase] = useState()
    const [alert, setAlert] = useState(false)

    const [updateRuleBase, { loading: updateloading, error: updateerror, data: updatedata }] = useMutation(updateRuleBaseGQL)
    const [deleteRuleBase, { loading: deleteloading, error: deleteerror, data: deletedata }] = useMutation(deleteRuleBaseGQL, {
        refetchQueries: [
            getRuleBase
        ],
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (!currentRuleBase) {
        const ruleBaseFromApi = data.customer.getRuleBase;
        setCurrentRuleBase(ruleBaseFromApi)
        return <p>Loading...</p>;
    }

    const handleControlledInputChange = (getRuleBase) => {
        const updatedRuleBase = Object.assign({}, currentRuleBase)
        updatedRuleBase[getRuleBase.target.name] = getRuleBase.target.value
        setCurrentRuleBase(updatedRuleBase)
    }

    // const updateSchema = (field, column, val) => {

    //     let updatedSchema = JSON.parse(currentRuleBase.schema)

    //     if (column == "examples") {
    //         updatedSchema.properties[field].examples[0] = val.target.value
    //     } else if (column == "field") {
    //         const oldField = updatedSchema.properties[field]
    //         delete updatedSchema.properties[field]
    //         updatedSchema.properties[val.target.value] = oldField
    //     } else {
    //         updatedSchema.properties[field][column] = val.target.value
    //     }

    //     let updatedRuleBase = Object.assign({}, currentRuleBase)
    //     updatedRuleBase["schema"] = JSON.stringify(updatedSchema)


    //     setCurrentRuleBase(updatedRuleBase)
    // }

    const parsedSchema = JSON.parse(currentRuleBase.schema)
    let schemaForTable = parsedSchema.properties

    if (!schemaForTable) {
        schemaForTable = {}
    }

    const saveRuleBase = () => {
        updateRuleBase({ variables: { customerId, ruleBaseId, ...currentRuleBase } })
        setAlert((prev) => !prev)
    }

    const deleteRuleBaseAction = () => {
        let confirmAction = window.confirm("Are you sure to delete this rulebase?");
        if (confirmAction) {
            const isDeletedOn = today
            deleteRuleBase({ variables: { customerId, ruleBaseId, isDeletedOn } })
            navigate("/rulebases")
        } else {
            alert("Action canceled");
        }
    }

    // const getSchema = () => {
    //     const keys = Object.keys(schemaForTable)
    //     let schema = Object.values(schemaForTable)
    //     for (let i = 0; i < schema.length; i++) {
    //         schema[i] = { ...schema[i], ...{ field: keys[i] } }
    //     }
    //     return schema
    // }

    // function addSchemaField() {
    //     // let addedSchema = JSON.parse(currentRuleBase.schema)

    //     // addedSchema.push({
    //     //     description: "",
    //     //     field: "",
    //     //     type: "",
    //     //     example: ""
    //     // })
    //     const addedSchema = {
    //         description: "",
    //         field: "",
    //         type: "",
    //         example: ""
    //     }
    //     console.log("CHECK added schema", addedSchema)
    //     currentRuleBase.schema.push(addedSchema)
    // }

    return (
        <div key={ruleBaseId}>
            <form className="RuleForm">
                <div className="EditRuleBase_Title">EDIT RULEBASE</div>
                <div className="rulebase-name">
                    <TextField label="Name" name="ruleBaseName" required autoFocus className="form-control"
                        value={currentRuleBase.ruleBaseName}
                        onChange={handleControlledInputChange}
                        size="small"
                    />
                    <div className="Button">
                        <Button
                            variant="outlined"
                            style={{ marginRight: "5px", position: "-webkit-sticky" }}
                            type="Save"
                            onClick={evt => {
                                evt.preventDefault()
                                saveRuleBase()
                            }}
                            className="btn btn-primary">Save</Button>

                        <Button
                            variant="outlined"
                            z-index="2"
                            onClick={() => deleteRuleBaseAction()}> Delete RuleBase </Button>
                    </div>
                </div>
                <fieldset>
                    <div className="form-group">
                        <Fade
                            in={alert}
                            timeout={{ enter: 1000, exit: 6000 }}
                            addEndListener={() => {
                                setTimeout(() => {
                                    setAlert(false)
                                }, 2000);
                            }}
                        >
                            <Alert sx={{ zIndex: "1000", marginBottom: "1rem", position: "fixed", bottom: "0", right: "0" }} icon={<CheckIcon fontSize="inherit" />} severity="success">
                                Save Successful
                            </Alert>
                        </Fade>
                        <label htmlFor="Name">RuleBase Inputs </label>
                        <div className="Description">Description: Setup inputs using JSON Schema format, that will be passed to the rulebase. Pay attention that types match your json inputs.</div>
                        <TextField name="schema" multiline required autoFocus fullWidth className="form-control"
                            value={currentRuleBase.schema}
                            onChange={handleControlledInputChange}
                            size="small"
                        />
                        {/* <TextField name="schema" multiline required autoFocus fullWidth className="form-control"
                            onChange={handleControlledInputChange}
                            size="small"
                        /> */}
                        {/* <div>
                            <div className="DeploymentForm">

                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Field</TableCell>
                                                <TableCell align="right">Type</TableCell>
                                                <TableCell align="right">Description</TableCell>
                                                <TableCell align="right">Example</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getSchema().map((schemaTableValueItem, i) => (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="right">
                                                        <TextField
                                                            size="small"
                                                            value={schemaTableValueItem.field}
                                                            onChange={evt => {
                                                                updateSchema(schemaTableValueItem.field, "field", evt)
                                                            }}
                                                        >
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            size="small"
                                                            value={schemaTableValueItem.type}
                                                            onChange={evt => {
                                                                updateSchema(schemaTableValueItem.field, "type", evt)
                                                            }}
                                                        >
                                                        </TextField>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <TextField
                                                            size="small"
                                                            value={schemaTableValueItem.description}
                                                            onChange={evt => {
                                                                updateSchema(schemaTableValueItem.field, "description", evt)
                                                            }}
                                                        >
                                                        </TextField>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <TextField
                                                            size="small"
                                                            value={schemaTableValueItem.examples}
                                                            onChange={evt => {
                                                                updateSchema(schemaTableValueItem.field, "examples", evt)
                                                            }}
                                                        >
                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="New">
                                    <Button
                                        variant="outlined"
                                        type="Save"
                                        onClick={evt => {
                                            evt.preventDefault()
                                            addSchemaField()

                                        }}

                                        className="btn btn-primary">New</Button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </fieldset>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="Name">Rule Outputs </label>
                        <div className="Description"><b>Description:</b> Setup outputs for rules using JSON Schema format. These attributes will be returned when the rule fires. These attributes are also searchable from the editor."</div>
                        <TextField name="metaDataSchema" multiline required autoFocus fullWidth className="form-control"
                            defaultValue={currentRuleBase.metaDataSchema}
                            onChange={handleControlledInputChange}
                            size="small"
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="Name">Rule Attributes </label>
                        <div className="Description"><b>Description:</b> Setup Attributes for rules using JSON Schema format. These attributes are used for notes and organizing rules. They are searchable from the editor but will not be returned when a rule fires. </div>
                        <TextField name="attributes" multiline required autoFocus fullWidth className="form-control"
                            defaultValue={currentRuleBase.attributes}
                            onChange={handleControlledInputChange}
                            size="small"
                        />
                    </div>
                </fieldset>
                {/* <div className="Save">
                    <Button
                        variant="outlined"
                        type="Save"
                        onClick={evt => {
                            evt.preventDefault()
                            saveRuleBase()
                        }}
                        className="btn btn-primary">Save</Button>
                </div> */}
            </form>
        </div>
    );
}