import React from "react"
import "./EditAttributes.css"
import { MenuItem, TextField } from '@mui/material'

export const EditAttributes = (props) => {
    const rule = props.rule;
    const attributes = props.attributes ? JSON.parse(props.attributes) : {}
    const attributesFromDynamoDB = props.rule.attributes ? JSON.parse(props.rule.attributes) : {}
    // console.log("attributes", attributes)

    function buildAttribute() {
        let attributeList = []

        //looping through the schema
        for (var schemaItem in attributes.properties) {
            let itemVal = ""
            let itemEnum = []
            // console.log("attributes.properties", attributes.properties)
            // console.log("attributesFromDynamoDB", attributesFromDynamoDB)
            // console.log("schemaItem", schemaItem)
            // console.log("attributes.properties[schemaItem]", attributes.properties[schemaItem])

            //finding enum 
            if (attributes.properties[schemaItem].enum) {
                itemEnum = attributes.properties[schemaItem].enum
            }

            //loop for making sure I have data
            for (var attributeIndex in attributesFromDynamoDB) {

                if (attributesFromDynamoDB[attributeIndex].name === schemaItem) {
                    itemVal = attributesFromDynamoDB[attributeIndex].val
                }

            } let item = { name: schemaItem, val: itemVal, type: attributes.properties[schemaItem].type, enum: itemEnum }

            attributeList.push(item)
            // console.log("item", item)
        }
        return attributeList;
    }

    const updateAttributeValByName = (value, name) => {
        let newAttributes = []
        let currentAttribute = buildAttribute()

        for (var attributeIndex in currentAttribute) {
            let attributeItem = currentAttribute[attributeIndex]

            if (attributeItem.name === name) {
                attributeItem.val = value

            }
            if (!attributeItem.usePriority) {
                attributeItem.usePriority = 0
            }
            newAttributes.push(attributeItem)
        }
        const updateRuleJson = { ...rule, attributes: JSON.stringify(newAttributes) }
        props.setRule({ ...updateRuleJson })
        // console.log("updateRuleJson", updateRuleJson)
    }

    // const updateAttributeCheckByName = (name) => {
    //     let newAttribute = []
    //     let currentAttribute = buildAttribute()

    //     for (var attributeIndex in currentAttribute) {
    //         let attributeItem = currentAttribute[attributeIndex]
    //         newAttribute.push(attributeItem)
    //     }
    //     const updateRuleJson = { ...rule, attributes: JSON.stringify(newAttribute) }
    //     props.setRule({ ...updateRuleJson })
    // }

    return (
        <div>
            {buildAttribute().map((item, index) =>
                <span key={index}>
                    {item.enum && item.enum.length > 0 ? (
                        <TextField
                            select
                            value={item.val}
                            sx={{ paddingRight: "5px", width: "30%", margin: '.5rem 0' }}
                            className="AttributeItem"
                            label={item.name} type="text" name="val" defaultValue={item.val}
                            size="small"
                            onChange={(e) => updateAttributeValByName(e.target.value, item.name)}
                        >
                            <MenuItem value=""></MenuItem>
                            {item.enum.map((single_condition_operator, index) =>
                                <MenuItem key={index} value={single_condition_operator}>{single_condition_operator}</MenuItem>
                            )}
                        </TextField>

                    ) :
                        <TextField
                            sx={{ paddingRight: "5px", width: "30%", margin: '.5rem 0' }}
                            className="AttributeItem"
                            label={item.name} type="text" name="val" defaultValue={item.val}
                            size="small"
                            onChange={(e) => updateAttributeValByName(e.target.value, item.name)} />
                    }


                </span>
            )
            }
        </div>
    )
}