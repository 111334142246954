import React from "react"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { GetRules } from "./Rules/Rules"
import { GetCustomer } from "./Customer/Customer"
import { EditRule } from "./Rules/EditRules/EditRule"
import { EditRuleBase } from "./RuleBases/EditRuleBase/EditRuleBase";
import { Login } from '../views/unauthenticated/Login';
import { Auth } from 'aws-amplify';
import { ForgotPassword } from "../views/unauthenticated/ForgotPassword";
import RuleBases from "./RuleBases/RuleBases"
import { RuleMatrix } from "./Rules/RuleMatrix";
import { GetApiKey } from "./ApiKey/GetApiKey";
import Layout from "./Layout";
import { SearchResults } from "./Search/SearchResults";


const RequireAuth = ({ children }) => {
  const [loading, setLoading] = React.useState(true)
  const [user, setUser] = React.useState()

  React.useEffect(() => {
    Auth
      .currentAuthenticatedUser()
      .then(u => {
        setUser(u)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
      })
  }, [])

  if (loading) {
    return <h1>Checking Auth...</h1>
  }
  if (user) {
    return children
  }

  return <Navigate to="/login" replace />
}

export const ApplicationViews = (user) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/rulebase/:ruleBaseId/edit" element={<RequireAuth><Layout><EditRuleBase /></Layout></RequireAuth>} />
        <Route path="/rules/:ruleBaseId" element={<RequireAuth><Layout><GetRules /></Layout></RequireAuth>} />
        <Route path="/rule/:ruleBaseId/:ruleId" element={<RequireAuth><Layout><EditRule /></Layout></RequireAuth>} />
        <Route path="/" element={<RequireAuth><Layout><GetCustomer /></Layout></RequireAuth>} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/api-keys" element={<RequireAuth><Layout><GetApiKey /></Layout></RequireAuth>} />
        <Route path="/rulebases" element={<RequireAuth><Layout><RuleBases /></Layout></RequireAuth>} />
        <Route path="/rulematrix/:ruleBaseId/:groupId" element={<RequireAuth><Layout><RuleMatrix /></Layout></RequireAuth>} />
        <Route path="/search/:searchQuery" element={<RequireAuth><Layout><SearchResults /></Layout></RequireAuth>} />
        <Route path="/search/:searchQuery/:ruleBaseId" element={<RequireAuth><Layout><SearchResults /></Layout></RequireAuth>} />
      </Routes>
    </BrowserRouter>
  )
}