import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AddRule } from "./EditRules/AddRule";
import "./Rules.css"
import React, { useState } from "react";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton'
import { DeleteOutline } from '@mui/icons-material';


// const getRuleGroups = gql`
// query getRuleGroups($customerId: String!, $ruleBaseId: String!) {
//   customer(id: $customerId) {
//     getRuleGroups(filter: {ruleBaseId: $ruleBaseId}){
//       items{
//         groupId
//         groupType
//         name
//         return
//     }
//   }
// }
// }
// `;

const getRuleBase = gql`
  query getRuleBase($customerId: String!, $ruleBaseId: String!) {
    customer(id: $customerId) {
      getRuleBase(filter: {ruleBaseId: $ruleBaseId}){
          ruleBaseId
          ruleBaseName
    }
  }
}
`;

const getRules = gql`
  query getRules($ruleBaseId: String!, $ruleId: Int!, $ruleListNextKey: String, $limit: Int) {
    customer{
      getRules(filter: {ruleBaseId: $ruleBaseId, ruleId: $ruleId, nextKey: $ruleListNextKey, limit: $limit}) {
      nextKey
      limit
      items {
        ruleId
        ruleName
        createdAt
        updatedAt
      }
    }
    }
  }
`;

const deleteRuleGQL = gql`
    mutation DeleteRule($ruleBaseId: String!, $ruleId: Int!, $isDeletedOn: String!) {
    updateRule(where: {ruleBaseId: $ruleBaseId, ruleId: $ruleId, isDeletedOn: $isDeletedOn}) {
        ruleId
        isDeletedOn
    }
  }
`;

export const GetRules = () => {
  const today = (new Date()).toISOString()
  let ruleBaseId = "1"
  let ruleId = 0;
  let navigate = useNavigate()
  let previousKey
  let limit = 25
  let [ruleListHistory, setRuleListHistory] = useState([])
  let ruleListNextKey = ""
  let customerId = "2"
  let updatedAt = ""

  const { ruleBaseId: ruleBaseIdFromParam } = useParams()

  const [deleteRule] = useMutation(deleteRuleGQL, {
    refetchQueries: [
      getRules // DocumentNode object parsed with gql

    ],
  });

  const { loading: ruleBaseNameloading, data: ruleBaseNamedata } = useQuery(getRuleBase, {
    variables: { customerId, ruleBaseId: ruleBaseIdFromParam },
  });

  // const { loading: ruleGrouploading } = useQuery(getRuleGroups, {
  //   variables: { customerId, ruleBaseId: ruleBaseIdFromParam },
  // });

  // console.log("RULEGROUPS", ruleGroupdata)

  //TO DO REFRESH GET RULES ON DATA
  if (ruleBaseIdFromParam) {
    ruleBaseId = ruleBaseIdFromParam
  }
  const { loading, error, data, refetch } = useQuery(getRules, {
    variables: { ruleBaseId, ruleId, ruleListNextKey, limit, updatedAt },
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });

  //console.log("DATA", data)

  if (loading || ruleBaseNameloading) return '';
  if (error) return <p>Error :(</p>;
  //console.log("DATA", data)

  const nextButtonClicked = () => {
    const ruleListNextKey = data.customer.getRules.nextKey
    setRuleListHistory(ruleListHistory => [...ruleListHistory, ruleListNextKey])
    refetch({ ruleListNextKey })
    //console.log(ruleListNextKey)

  }

  function displayLocalTime(myDate) {
    const date = new Date(myDate)
    return date.toLocaleString()
  }

  const previousButtonClicked = () => {
    previousKey = ruleListHistory[ruleListHistory.length - 2]
    ruleListHistory = ruleListHistory.splice(-1)
    refetch({ ruleListNextKey: previousKey })

  }

  const deleteRuleAction = (ruleId) => {
    let confirmAction = window.confirm("Are you sure to delete this rule?");
    //console.log("RULE CHECK, ruleId:", ruleId)
    if (confirmAction) {
      deleteRule({ variables: { ruleBaseId, ruleId, isDeletedOn: today } })
    } else {
      alert("Action canceled");
    }
  }
  return (
    <>
      {/* <Search /> */}
      <div className="RuleBaseName">
        RULEBASE: {ruleBaseNamedata?.customer?.getRuleBase?.ruleBaseName}
      </div>
      <div className="RuleButtons">
        <AddRule />
        <Button
          variant="outlined"
          onClick={() => (
            navigate("/rulebase/" + ruleBaseId + "/edit")
          )}>
          Edit RuleBase
        </Button>
      </div>

      {/* {ruleGroupdata && ruleGroupdata.customer.getRuleGroups.items.map(({ groupId, name, index }) => (
        <div className="RuleGroups" key={index}>
          <div
            onClick={() => (
              navigate("/rulematrix/" + ruleBaseId + "/" + groupId))
            }> {name}
          </div>
        </div>
      ))} */}

      <TableContainer>
        <Table className="Rules">


          <TableHead>
            <TableRow>
              <TableCell>Rule ID</TableCell>
              <TableCell align="left">Rule Name</TableCell>
              <TableCell align="left">Last Updated</TableCell>
              <TableCell align="left"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody align="left">
            {data && data.customer.getRules.items.map(({ ruleId, ruleName, updatedAt }) => (
              <TableRow key={ruleId}
                style={{ cursor: "pointer" }}
              >
                <TableCell onClick={() => {
                  navigate("/rule/" + ruleBaseId + "/" + ruleId)
                }}>{ruleId}
                </TableCell>
                <TableCell onClick={() => {
                  navigate("/rule/" + ruleBaseId + "/" + ruleId)
                }}>
                  {ruleName}
                </TableCell>

                {updatedAt !== null ? (
                  <TableCell>
                    {displayLocalTime(updatedAt)}
                  </TableCell>
                ) :
                  <TableCell></TableCell>
                }

                <TableCell>
                  <IconButton style={{ borderRadius: '50%', padding: '8px' }} onClick={evt => {
                    evt.preventDefault()
                    deleteRuleAction(ruleId)
                  }}>
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {ruleListHistory[0] ? (
            <Button variant="outlined"
              onClick={evt => {
                evt.preventDefault()
                previousButtonClicked()
              }}
            > Previous </Button>
          ) : null}

          {data.customer.getRules.nextKey != null ? (
            <Button variant="outlined"
              onClick={evt => {
                evt.preventDefault()
                nextButtonClicked()
              }}
            > Next </Button>
          ) : null}
        </Table>
      </TableContainer>
    </>
  );
};