import { useMutation, gql } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';


const addRuleGQL = gql`
mutation CreateRule($ruleBaseId: String!) {
  addRule(where: {ruleBaseId: $ruleBaseId}) {
    ruleBaseId
    ruleId
  }
}
`;

export const AddRule = () => {
  const { ruleBaseId } = useParams()
  let navigate = useNavigate()

  const [createRuleDB, { loading, error, data }] = useMutation(addRuleGQL, {
  });

  useEffect(() => {
    if (data && data.addRule) {
      navigate("/rule/" + ruleBaseId + "/" + data.addRule.ruleId)
    }

  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  function createRule() {
    createRuleDB({ variables: { ruleBaseId } })
  }

  return (
    <Button
      variant="outlined"
      type="add"
      className="btn btn-primary"
      onClick={evt => {
        evt.preventDefault()
        createRule()
      }}>
      Add Rule
    </Button>
  )
}