import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { MenuItem } from "@mui/material";
import "./Search.css"

const getRuleBases = gql`
  query getRuleBases {
    customer {
      getRuleBases{
        items {
          ruleBaseId
          ruleBaseName
    }
    }
  }
}
`;

const SearchBar = () => {
    const { searchQuery: searchStringFromParam, ruleBaseId: ruleBaseIdFromParam } = useParams()
    const [searchQuery, setSearch] = useState(searchStringFromParam || "")
    const [ruleBaseId, setRuleBaseId] = useState(ruleBaseIdFromParam || "")
    let ruleBaseName = ""
    let ruleBase = {}
    let navigate = useNavigate()

    const setSearchValue = (val) => {
        setSearch(val)
    }

    const setRuleBaseIdValue = (val) => {
        if (val == "none") {
            setRuleBaseId("")
        } else {
            setRuleBaseId(val)
        }

    }
    const runSearch = () => {
        if (searchQuery && searchQuery.length > 0)
            navigate("/search/" + searchQuery + "/" + ruleBaseId)
    }



    const { loading, error, data } = useQuery(getRuleBases, {
        variables: { ruleBaseName },
    });


    if (error) return <p>NavBar Error: </p>;

    if (data && data.customer) {
        ruleBase = data.customer.getRuleBases.items
    }



    return (
        <div className="topnav">
            <TextField label="Rule Search" type="search" name="searchRule"
                sx={{ paddingBottom: "5px", paddingRight: "5px" }}
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        runSearch()
                    }
                }}
            />

            <TextField
                select
                label="Filter"
                size="small"
                value={ruleBaseId == "" ? "none" : loading ? "loading" : ruleBaseId}
                sx={{ paddingBottom: "5px", paddingRight: "5px" }}
                onChange={(e) => setRuleBaseIdValue(e.target.value)}
            >
                <MenuItem
                    value="none"
                > All RuleBases
                </MenuItem>

                {ruleBase && ruleBase.length > 0 && ruleBase.map(({ ruleBaseName, ruleBaseId }) => (
                    <MenuItem key={ruleBaseId} value={ruleBaseId} > {ruleBaseName}
                    </MenuItem>
                ))}

                {loading ? (<MenuItem
                    value="loading"
                > Loading...
                </MenuItem>) : ''}

            </TextField>

            <Button variant="outlined"
                onClick={() => runSearch()}
            > Search</Button>


        </div>
    )
}
export default SearchBar