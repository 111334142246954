import './App.css';
import { ApplicationViews } from './components/ApplicationViews';


function App() {

  return (


    <div className='App'>
      <ApplicationViews />

    </div>
  )
}
export default App;
