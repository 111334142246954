import { AddRuleBase } from "./EditRuleBase/AddRuleBase"
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import './RuleBases.css'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';


const getRuleBases = gql`
  query getRuleBases {
    customer {
      getRuleBases{
        items {
          ruleBaseId
          ruleBaseName
    }
    }
  }
}
`;

const RuleBases = () => {
    let ruleBaseName = ""
    let ruleBase = {}

    const { loading, error, data } = useQuery(getRuleBases, {
        variables: { ruleBaseName },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>NavBar Error: </p>;

    if (data && data.customer) {
        ruleBase = data.customer.getRuleBases.items
    }

    return (
        <TableContainer component={Paper} className="Table_Rulebase">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            RULEBASES
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <AddRuleBase
                                ruleBaseItems={data.customer.getRuleBases.items}
                                user
                            />
                        </TableCell>
                    </TableRow>
                    {ruleBase.map(({ ruleBaseName, ruleBaseId }) => (
                        <TableRow key={ruleBaseId}>
                            <TableCell>
                                <Link className="ruleBase-link"
                                    to={`/rules/${ruleBaseId}`}
                                    style={{ cursor: "pointer" }}
                                >{ruleBaseName}</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default RuleBases