import { NavBar } from "./NavBar/NavBar";
import SearchBar from "./Search/Search"
import { Box } from "@mui/material";

const Layout = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', width: '100vw', height: '100%', minHeight: '100vh' }}>
            <NavBar />
            <Box sx={{ width: 'calc(100% - 130px)', marginLeft: '130px', padding: '1rem', boxSizing: 'border-box' }}>
                <SearchBar />
                <Box >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default Layout