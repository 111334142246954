import { useParams } from "react-router-dom";
import React from "react";
import { useQuery, gql } from "@apollo/client";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const getRuleGroups = gql`
query getRuleGroups($customerId: String!, $ruleBaseId: String!, $ruleGroupId: String!) {
  customer(id: $customerId) {
    getRuleGroups(filter: {ruleBaseId: $ruleBaseId, ruleGroupId: $ruleGroupId}){
      items{
        groupType
        name
        return
    }
  }
}
}
`;

export const RuleMatrix = () => {
    const { ruleBaseId: ruleBaseIdFromParam } = useParams()
    const { groupId: ruleGroupIdFromParam } = useParams()
    const customerId = "3"
    const ruleBaseId = ruleBaseIdFromParam
    const ruleGroupId = ruleGroupIdFromParam

    const { loading, error, data } = useQuery(getRuleGroups, {
        variables: { customerId, ruleBaseId, ruleGroupId },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const rows = []
    const columns = []

    // var Box = {
    // [
    // coordinates: [x: 0, y: 0],
    // column: [x > 5],
    // row: [y < 1],
    // value: [17]
    // ],
    // [
    // coordinates: [x: 0, y: 1],
    // column: [x > 5],
    // row: [y = 2],
    // value: [37]
    // ],
    // [
    // coordinates: [x: 1, y: 0],
    // column: [x < 4],
    // row: [y < 1],
    // value: [19]
    // ],
    // [
    // coordinates: [x: 1, y: 1],
    // column: [x < 4],
    // row: [y = 2],
    // value: [27]
    // ]
    // }


    return (
        <>
            Rule Matrix: {data.customer.getRuleGroups.items[0].name}
            <div>
                <Box sx={{ height: 400, width: '100%' }}>
                    {/* <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                    /> */}
                </Box>
            </div>
        </>
    )
}