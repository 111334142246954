import "./GetApiKey.css"
import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { uuid } from 'uuidv4';
import { useQuery, useMutation, gql } from "@apollo/client";
import IconButton from '@mui/material/IconButton'
import { DeleteOutline } from '@mui/icons-material'
import { Auth } from 'aws-amplify';

const getCustomer = gql`
  query GetCustomer($customerId: String!) {
    customer(id: $customerId) {
      getCustomer {
        customerId
        customerName
        customerDeployment
        apiKeys
    }
    }
  }
`;

const updateCust = gql`
    mutation UpdateCustomer($customerId: String!, $customerDeployment: String, $apiKeys: String) {
        updateCustomer(where: {customerId: $customerId, customerDeployment: $customerDeployment, apiKeys: $apiKeys}){
            customerDeployment
            apiKeys
        }
    }
`

export const GetApiKey = () => {

    const [customerId, setCustomerId] = useState("")
    useEffect(() => {
        async function getUser() {
            let user = await Auth.currentUserInfo()
            setCustomerId(user.attributes["custom:customerId"])
            console.log("USER", user.attributes["custom:customerId"])

        }
        if (customerId == "") {
            getUser()
        }

    }, [])

    const today = (new Date()).toISOString()
    let apiKeys = []

    const { loading: customerLoading, error: customerError, data: customerData } = useQuery(getCustomer, { variables: { customerId } });
    const [updateCustomer, { loading: updateloading, error: updateerror, data: updatedata }] = useMutation(updateCust, {
        refetchQueries: [
            getCustomer
        ],
    });

    if (customerLoading) return <p>Loading Customer Data...</p>;
    if (customerError) return <p>Error Loading Customer</p>;

    const currentKeys = customerData.customer.getCustomer.apiKeys.length > 0 ? JSON.parse(customerData.customer.getCustomer.apiKeys) : []

    if (currentKeys.length > 0) {
        const sortedKeys = currentKeys.sort((a, b) => {

            if (a.stage < b.stage) { return -1; }
            if (a.stage > b.stage) { return 1; }
            return 0;
        })
    }

    function displayLocalTime(myDate) {
        const date = new Date(myDate)
        return date.toLocaleString()
    }

    function encryptKey(plaintext) {
        var s = [];
        for (var i = 0; i < plaintext.length; i++) {
            var j = plaintext.charCodeAt(i);
            if ((j >= 33) && (j <= 126)) {
                s[i] = String.fromCharCode(33 + ((j + 14) % 94));
            } else {
                s[i] = String.fromCharCode(j);
            }
        }
        return s.join('');
    }

    function addKeyInfo(stage) {
        let keyId = uuid().replaceAll('-', '')

        const apiKey = {
            key: keyId,
            stage: stage,
            createdAt: today,
            isDeletedOn: "",
            customerKey: encryptKey(customerId + ":" + stage + ":" + keyId)
        }
        console.log(apiKey)
        let keysArray = []
        if (currentKeys && currentKeys.length > 0) {
            keysArray = currentKeys
        }
        keysArray.push(apiKey)
        updateCustomer(({ variables: { customerId, apiKeys: JSON.stringify(keysArray) } }))
    }

    const deleteRuleAction = (keyItem) => {
        let confirmAction = window.confirm("Are you sure to delete this key?")

        if (confirmAction) {
            for (var keyIndex in currentKeys) {
                let singleKey = currentKeys[keyIndex]

                if (singleKey.key == keyItem.key) {
                    keyItem.isDeletedOn = today
                    singleKey = keyItem
                    currentKeys[keyIndex] = singleKey
                    console.log(currentKeys)
                }
            }
            updateCustomer(({ variables: { customerId, apiKeys: JSON.stringify(currentKeys) } }))
        } else {
            alert("Action canceled");
        }
    }

    return (
        <div className="Apikey">

            <div className="ApiKey_header">API KEYS</div>

            <div className='apiKeys--button-container'>
                <Button
                    variant="outlined"
                    onClick={evt => {
                        evt.preventDefault()
                        addKeyInfo("production")

                    }}>Add Api Key To Production</Button>

                <Button
                    variant="outlined"
                    onClick={evt => {
                        evt.preventDefault()
                        addKeyInfo("development")

                    }}>Add Api Key To Development</Button>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>API Key</TableCell>
                            <TableCell>Date Created</TableCell>
                            <TableCell>Stage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentKeys.length > 0 ? (
                            <>
                                {currentKeys.length > 0 && currentKeys.map((keyItem, index) => (
                                    keyItem.isDeletedOn != undefined && keyItem.isDeletedOn.length == 0 ? (
                                        <TableRow
                                            key={index}>
                                            <TableCell>{keyItem.customerKey}</TableCell>
                                            <TableCell>{displayLocalTime(keyItem.createdAt)}</TableCell>
                                            <TableCell>{keyItem.stage}</TableCell>
                                            <TableCell>
                                                <IconButton style={{ borderRadius: '50%', padding: '8px' }} onClick={() => {
                                                    deleteRuleAction(keyItem)
                                                }}>
                                                    <DeleteOutline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ) : null
                                ))}
                            </>
                        ) : []}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}