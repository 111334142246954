import { useQuery, gql } from "@apollo/client";
import "./Customer.css"
import React from "react";
import { DeployRules } from "../DeployRules/deployRules";
import { Grid } from '@mui/material'

const getRuleBases = gql`
  query getRuleBases{
    customer {
      getRuleBases{
        items {
          ruleBaseId
          ruleBaseName
    }
    }
  }
}
`;

export const GetCustomer = () => {

  let ruleBaseName = ""
  let ruleBase = {}

  const { loading, error, data } = useQuery(getRuleBases, {
    variables: { ruleBaseName },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if (data && data.customer) {
    ruleBase = data.customer.getRuleBases.items
  }
  return (
    <>
      <Grid className="Customer" container>
        <Grid item xs={12}>
          <DeployRules />
        </Grid>
      </Grid>
    </>
  )
}